import React, { useEffect } from "react";
import strengthLogo from "../../assets/strengthLogo.png";
import myofascial from "../../assets/myofascial.jpeg";
import myofascial2 from "../../assets/myofascial_2.jpeg";
import BookingButton from "./SubComponents/BookingButton";
import "../../styles/treatments.css";
import MfrAccordion from "./SubComponents/MfrAccordion";

const MyofascialRelease = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="outer-treatments">
      <img className="main-logo-treatments" src={strengthLogo} />
      <section className="treatment-section">
        <h3 className="treatment-heading">Myofascial Release</h3>
        <p className="treatment-text">
          Myofascial release (MFR) is a very specialised slow treatment of
          stretching, applying pressure, and manipulation over trigger points
          that releases restrictions within the fascial network. It helps to
          enable stuck, resistant, and dehydrated areas of the body to become
          soft, malleable, and hydrated.
        </p>
        <p className="treatment-text">
          If you have had physical or emotional trauma causing inflammation and
          scaring, the body can lose pliability, it can become dehydrated,
          tight, and painful.
        </p>
      </section>
      <section className="treatment-section">
        <h3 className="treatment-heading">
          Myofascial release can be beneficial to many conditions, including:
        </h3>
        <div className="condition-grid">
          <ul className="condition-list-solo">
            <li className="condition-item">Chronic joint or muscle injuries</li>
            <li className="condition-item">
              Injuries or pain related to repetitive movements
            </li>
            <li className="condition-item">Headaches and migraines</li>
            <li className="condition-item">Neurological dysfunction</li>
            <li className="condition-item">Stress</li>
            <li className="condition-item">Fibromyalgia</li>
            <li className="condition-item">Chronic fatigue syndrome</li>
            <li className="condition-item">Arthritis</li>
          </ul>
        </div>
        <div className="mfr-image-container">
          <img className="treatment-image" src={myofascial} />
        </div>
        <h3 className="treatment-heading">It can increase:</h3>
        <div className="condition-grid">
          <ul className="condition-list-solo">
            <li className="condition-item">Energy levels</li>
            <li className="condition-item">Breathing capacity</li>
            <li className="condition-item">Improve circulation</li>
            <li className="condition-item">Restore muscle function</li>
            <li className="condition-item">
              Help the body to realign the body’s posture
            </li>
            <li className="condition-item">
              Help the body’s internal balance promoting self-healing
            </li>
            <li className="condition-item">
              Release physical and emotional strain
            </li>
            <li className="condition-item">
              After a treatment a person can feel “freer” or “lighter”
            </li>
          </ul>
        </div>
        <div className="mfr2-image-container">
          <img className="wide-treatment-image" src={myofascial2} />
        </div>
        <MfrAccordion />
      </section>
      <div className="treatment-separator"></div>
      <h3 className="treatment-heading">How do I book my session with you?</h3>
      <p className="treatment-text">
        Use our Contact form to book a session with me.
      </p>
      <BookingButton />
      <div className="treatment-separator"></div>
    </div>
  );
};

export default MyofascialRelease;
