import React, { useEffect } from "react";
import strengthLogo from "../../assets/strengthLogo.png";
import books from "../../assets/books.webp";
import "../../styles/treatments.css";
import styled from "styled-components";
import BookingButton from "./SubComponents/BookingButton";

const DesktopWrapper = styled.div`
  display: none;
  @media screen and (min-width: 960px) {
    display: inline-block;
  }
`;

const HealthCheck = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="outer-treatments">
      <img className="main-logo-treatments" src={strengthLogo} />
      <section className="treatment-section">
        <h3 className="treatment-heading">Health Check</h3>
        <p className="treatment-text">
          A Strength In You, is now offering a 45 minute Health Check. This
          Includes a recorded discussion of your current situation and lifestyle
          and written record of your base line observations: manual blood
          pressure, pulse, oxygen saturations, blood glucose, weight and urine
          dip stick. These results are for you to keep. You can bring your
          record card back and have and your observations taken and documented
          again at any point in the future.
        </p>
        <p className="treatment-text">
          You will be advised to contact your GP if there are any concerns with
          any of your observations. It is your responsibility to do as advised.
        </p>
      </section>
      <div className="treatment-separator"></div>
      <h3 className="treatment-heading">How do I book my session with you?</h3>
      <p className="treatment-text">
        Use our Contact form to book a session with me.
      </p>
      <BookingButton />
      <div className="treatment-separator"></div>
    </div>
  );
};

export default HealthCheck;
