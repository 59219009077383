import Accordion from "react-bootstrap/Accordion";

import React from "react";
import "../../../styles/accordion.css";

const OncologyAccordion = () => {
  return (
    <div className="accordion-container">
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header style={{ color: "#036c7b" }}>
            The Treatment
          </Accordion.Header>
          <Accordion.Body>
            <div className="flex-wrapper-inner">
              <p className="treatment-text-2">
                As with most other massage therapies, you will be laid on a
                massage table for your treatment. You will need to expose the
                areas to be massaged, but your dignity and respect will be
                maintained throughout, we have towels, gowns and plenty of
                cushioning support.
              </p>
              <p className="treatment-text-2">
                The amount of pressure your therapist applies when massaging you
                can vary, but it will be very gentle – you are in control of the
                pressure and speed. Most people say that having a massage is
                very relaxing and soothing. But you should let your therapist
                know if you feel uncomfortable and want them to stop or change
                speed or pressure at any time at any time.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Treatment Aftercare</Accordion.Header>
          <Accordion.Body>
            <div className="flex-wrapper-inner">
              <p className="treatment-text-2">
                You might be thirsty afterwards, and with this and because of
                the movement of lymph you must drink plenty of water.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Side Effects</Accordion.Header>
          <Accordion.Body>
            <div className="flex-wrapper-inner">
              <p className="treatment-text-2">
                Most people don’t have any side effects from having a massage.
                But you might feel a bit light headed, tired or thirsty
                afterwards. Some people can feel a bit emotional or tearful for
                a while.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Contraindications</Accordion.Header>
          <Accordion.Body>
            <div className="flex-wrapper-inner">
              <p className="treatment-text-2">
                Before your massage a very thorough assessment will be performed
                to ascertain your medical treatment and history.
              </p>
              <p className="treatment-text-2">
                Like many treatments there are contraindications for the
                therapy:
              </p>
              <div className="treatment-grid">
                <ul className="condition-list">
                  <li className="condition-item">
                    Recent incisions/ open areas/ bruises or sores must be
                    avoided
                  </li>
                  <li className="condition-item">
                    DVT, PE, areas of poor circulation and varicose veins or
                    signs of, i.e. unexplained temperature change,
                    discolouration of a limb, inflammation altered sensation,
                    pain / tenderness
                  </li>
                  <li className="condition-item">
                    Infection, (fevers, inflammation, erythema, heat,
                    tenderness)
                  </li>
                  <li className="condition-item">Lymhodema</li>
                  <li className="condition-item">
                    Severely low white blood cell count - leucopoenia
                  </li>
                  <li className="condition-item">
                    Severe thrombocytopenia (low platelets)
                  </li>
                  <li className="condition-item">
                    Sites of active tumour or lymph node
                  </li>
                  <li className="condition-item">
                    Areas of current radiotherapy treatment (after 1 month is
                    acceptable)
                  </li>
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default OncologyAccordion;
