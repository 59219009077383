import React, { useEffect } from "react";
import "../styles/testimonials.css";
import strengthLogo from "../assets/strengthLogo.png";

const Testimonials = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="outer-review">
      <img className="main-logo-treatments" src={strengthLogo} />
      <h3 className="review-heading">Testimonials</h3>
      <section className="review">
        <p className="review-text">
          Amazing treatment and highly recommended. Jacky makes a huge
          difference and the positive effects really do last. Her nutritional
          advice is also spot on and is completely individually tailored.
        </p>
        <p className="reviewer">Kathy Gribbon</p>
        <div className="treatment-separator"></div>
      </section>
      <section className="review">
        <p className="review-text">
          I had a cranial sacral therapy on Monday not my first but Jackie is
          amazing so calming and such a beautiful soul and so knowledgeable. I
          certainly felt some shifts in my body and slept so well can’t wait to
          see what the next few days brings. Thank you so much Jackie I am so
          full of gratitude
        </p>
        <p className="reviewer">Tania Campbell</p>
        <div className="treatment-separator"></div>
      </section>
      <section className="review">
        <p className="review-text">
          I had another great CranioSacral Therapy session with Jacky yesterday.
          This treatment is amazing. I could feel so much movement and energy
          moving around and both Jacky and I had visions during it. Jacky is a
          great therapist with a unique combination of medical and spiritual
          knowledge. She’s based at the Business Centre on Blackwell Lane in
          Darlington and I can’t recommend her highly enough!
        </p>
        <p className="reviewer">
          Debbie Stokoe (Entrepeneur - Butterfly Communications)
        </p>
        <div className="treatment-separator"></div>
      </section>
      <section className="review">
        <p className="review-text">
          I had a CST session with Jacky last week and it was amazing! After
          treatment all my aches and pains had melted away but I also feel
          clearer and more focused than I had for ages. I really needed to
          remember Jacky’s advice about staying hydrated after therapy as I
          drank as much as if I’d been running all day! I could feel the
          energies moving around my body, clearing blockages and I will
          definitey be booking another session soon. Highly recommended.
        </p>
        <p className="reviewer">Rosie Brent (Owner - Rosiebrent.com)</p>
        <div className="treatment-separator"></div>
      </section>
      <section className="review">
        <p className="review-text">
          I have suffered with chronic back pain for the last 10 years, so
          decided to see Jacky for CranioSacral Therapy. As I had heard how good
          it was. The treatment itself is very relaxing and Jacky made me feel
          very at ease, but I was sceptical if it would help me or not. When I
          get home from work and sit down for awhile I always seize up and I'm
          in agony once I try to stand again. When I get out of bed on a morning
          I'm in a lot of pain until my pain meds start kicking in. But after
          one session with Jacky I noticed a difference straight away. There was
          no pain on an evening or when I got up. I know I will have to have a
          few appointments to getthe full benefit, but I can highly recommend
          this treatment. Thank you Jacky x
        </p>
        <p className="reviewer">M. Kidd Helm</p>
        <div className="treatment-separator"></div>
      </section>
      <section className="review">
        <p className="review-text">
          Highly recommend Jacky. She put me at ease straight away and was very
          knowledgeable. Lovely lady.
        </p>
        <p className="reviewer">Anonymous</p>
        <div className="treatment-separator"></div>
      </section>
      <section className="review">
        <p className="review-text">
          Brilliant practitioner, and really lovely and friendly lady. I've been
          seeing Jacky for a few months now for ongoing CranioSacral Therapy and
          emotional release to treat my PTSD. Jacky has helped release so much
          deep tension and stress held in my body to address the somatic part of
          my condition and helped in my recovery. It's crazy how pressure in
          your neck can release so much tightness in your hips which sports
          massage can't shift. Jacky has also been an emotional support applying
          her knowledge and expertise from her other disciplines to guide me to
          live healthier and even gave me plenty of career advice during our
          chats! A true professional, I couldn't recommend her enough.
        </p>
        <p className="reviewer">H. Hayllar</p>
      </section>
    </div>
  );
};

export default Testimonials;
