import React, { useEffect } from "react";
import BookingButton from "./SubComponents/BookingButton";
import "../../styles/treatments.css";
import pain from "../../assets/pain.jpg";
import strengthLogo from "../../assets/strengthLogo.png";

const PainManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="outer-treatments">
      <img className="main-logo-treatments" src={strengthLogo} />
      <section className="treatment-section">
        <h3 className="treatment-heading">
          Holistic Pain Management Practitioner
        </h3>
        <p className="treatment-text">
          In a world so dependent on quick fixes and eagerly prescribed pain
          relief that can quickly become addictive, wouldn’t it be better to
          understand your pain and the reasons why we have it, in order to
          alleviate it and reduce it for from reappearing?
        </p>
        <img className="pain-image-container" src={pain} />
        <p className="treatment-text">
          There is much to be stated from the “mind over matter debate”. We know
          that the feelings of pain come from our brain causing us to adapt our
          behaviour to reduce it.
        </p>
        <p className="treatment-text">
          Using evidence based research, techniques and studies, with you
          keeping journals of your pain, alongside your sleep, nutrition,
          movements, thoughts and feelings, I can give you support and
          encouragement to use proven methods to manage your pain in order for a
          better quality of life and reduce the need for medicines.
        </p>
        <p className="treatment-text">
          We look at different ways of supporting you with your pain, and how to
          work through the road to recovery.
        </p>
        <p className="treatment-text">
          The first consultation can last up to 90 minutes and we will go
          through a very thorough assessment.
        </p>
        <p className="treatment-text">
          You will take away information to enable you to keep a journal of
          symptoms and information over the next week (or however long you need
          in between).
        </p>
        <p className="treatment-text">
          This will help us analyse the information and learn different helpful
          methods for your benefit, during our next 45 minute consultation.
        </p>
      </section>

      <div className="treatment-separator"></div>
      <h3 className="treatment-heading">How do I book my session with you?</h3>
      <p className="treatment-text">
        Use our Contact form to book a session with me.
      </p>
      <BookingButton />
      <div className="treatment-separator"></div>
    </div>
  );
};

export default PainManagement;
