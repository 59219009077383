import React, { useEffect } from "react";
import strengthLogo from "../../assets/strengthLogo.png";
import lymph from "../../assets/lymph.jpg";
import BookingButton from "./SubComponents/BookingButton";
import "../../styles/treatments.css";
import LymphaticAccordion from "../Treatments/SubComponents/LymphaticAccordion";
import LymphaticList from "../Treatments/SubComponents/LymphaticList"

const LymphaticDrainageMassage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="outer-treatments">
      <img className="main-logo-treatments" src={strengthLogo} />
      <section className="treatment-section">
        <h3 className="treatment-heading">Manual Lymphatic Drainage massage</h3>
        <p className="treatment-text">
          Manual lymphatic drainage massage, or MLD massage, is a gentle but
          powerful massage that encourages the movement of lymph fluid through
          the body.
        </p>
      </section>
      <section className="treatment-section">
        <h3 className="treatment-heading">It is beneficial for:</h3>
        <div className="condition-grid">
          <ul className="condition-list-solo">
            <li className="condition-item">Cleansing</li>
            <li className="condition-item">Relaxing</li>
            <li className="condition-item">Relieving pain</li>
            <li className="condition-item">Improving the immune system</li>
          </ul>
        </div>
        <p className="treatment-text">
          A lymphatic drainage massage can assist in detoxifying the body by
          stimulating the tissue to remove lymph fluid build-up. It facilitates
          the removal of metabolic waste, excess water, toxins, bacteria, and
          unhelpful substances from tissue via the venous system and lymph
          vessels.
        </p>
        <div className="lymph-image-container">
          <img className="wide-treatment-image" src={lymph} />
        </div>
        <div className="treatment-separator"></div>
        <h3 className="treatment-heading">
          What symptoms and conditions can Lymphatic drainage help?
        </h3>
        <LymphaticList />
        <p className="treatment-text">
          Prices are determined by the length of treatment.
        </p>
        <p className="treatment-text">
          Treatments are tailored to each individual and condition, and can be
          specific to areas of the body.
        </p>
        <LymphaticAccordion />
      </section>
      <div className="treatment-separator"></div>
      <h3 className="treatment-heading">How do I book my session with you?</h3>
      <p className="treatment-text">
        Use our Contact form to book a session with me.
      </p>
      <BookingButton />
      <div className="treatment-separator"></div>
    </div>
  );
};

export default LymphaticDrainageMassage;
