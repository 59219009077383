import React, { useEffect } from "react";
import strengthLogo from "../../assets/strengthLogo.png";
import menopause from "../../assets/menopause.jfif";
import menopauseLogo from "../../assets/menopause-champion.png";
import menopauseBanner from "../../assets/menopause-banner.jpg";
import BookingButton from "./SubComponents/BookingButton";
import "../../styles/treatments.css";
import MenopauseBullets from "./SubComponents/MenopauseBullets";


const Reflexology = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="outer-treatments">
      <img className="main-logo-treatments" src={strengthLogo} />
      <img className="menopause-logo" src={menopauseLogo} />
      <section className="treatment-section">
        <h3 className="treatment-heading">Managing Menopause Naturally</h3>
        <p className="treatment-text">
          At A Strength In You we appreciate how everybody’s body is different,
          and that everyone is individual in their thoughts and feeling s and
          symptoms. We are passionate about promoting every person’s uniqueness
          and tailoring treatments to each persons needs.
        </p>
        <p className="treatment-text">
          The menopause is yet another cycle a woman’s body goes through,
          without much discussion, thought, or education by the health
          professionals. Not everyone wants or responds to medication. And I ask
          the question: if our body is becoming “naturally” depleted in some
          hormones, why does the health service then try to replace them,
          instead of supporting the body with change?
        </p>
        <div className="menopause-phone-image-container">
          <img className="wide-treatment-image" src={menopause} />
        </div>
        <p className="treatment-text">
          I also feel it is important for males to understand what is happening
          in the female’s body, females they care about. Let’s face it,
          knowledge is power. The more we understand something, the more we can
          accept the changes and the adjustments we need to make to help our
          symptoms.
        </p>
        <p className="treatment-text">
          The Management of Menopause Naturally programme looks at your
          symptoms, your lifestyle and what changes your body is going through.
          It encourages you to learn, understand, accept, and embrace the
          transformations. It supports you and your family (if required) to deal
          with the signs and symptoms from perimenopause through to menopause,
          and being post-menopausal.
        </p>
      </section>
      <section>
        <div className="treatment-separator"></div>
        <p className="treatment-text">
          There are over 40 “common” symptoms we can start with. These, like
          I’ve mentioned are “common” however your body will not follow what the
          medical books state, or start to change when the medical books state
          we will.
        </p>
        <p className="treatment-text">
          For a quick guide, you may have noticed differences in your body such
          as:
        </p>
        <MenopauseBullets />

        <div className="treatment-separator"></div>

        <p className="treatment-text">
          If you have experienced one, or some of the symptoms above, you could
          be peri menopausal, or menopausal.
        </p>

        <p className="treatment-text">
          If you wish, we can take blood tests using Medichecks private tests to
          ascertain your hormone levels, thyroid functioning and blood count. We
          can assess for many different nutrients, enzyme, hormone levels and
          thyroid functioning to help us understand the symptoms you are
          experiencing and “work out the best way to supervise them.”
        </p>

        <p className="treatment-text">
          You can book in for a consultation, a one-to-one assessment and
          discussion or you can book the programme where you can go through it
          at your own pace or with regular appointments. We can have then have
          follow up sessions if you require, and you can also join a group of
          people who want to meet up and discuss and work through symptoms, and
          techniques they have found helpful and useful.
        </p>
        <p className="treatment-text">
          Many treatments offered by A Strength In You are helpful for all the
          stages of menopause. Give me a call if you’d like to discuss.
        </p>
      </section>
      <div className="treatment-separator"></div>
      <h3 className="treatment-heading">How do I book my session with you?</h3>
      <p className="treatment-text">
        Use our Contact form to book a session with me.
      </p>
      <BookingButton />
      <div className="treatment-separator"></div>
    </div>
  );
};

export default Reflexology;
