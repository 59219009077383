import React from "react";
import "../../../styles/conditions.css";

const ReflexologyBullets = () => {
  return (
    <div className="condition-grid">
      <ul className="condition-list-dual">
        <li className="condition-item">
          It does not use any drugs or chemicals and is a helpful choice for
          many health problems.
        </li>
        <li className="condition-item">
          Research suggests it can reduce pain and stimulate the body to release
          endorphins.
        </li>
        <li className="condition-item">
          It can help maintain physical flexibility.
        </li>
        <li className="condition-item">
          It relaxes the limbs, especially the feet, and the rest of the body.
        </li>
        <li className="condition-item">
          It is documented to boost the immune system.
        </li>
        <li className="condition-item">
          It can promote recovery from physical injury, especially for the hands
          and feet.
        </li>
        <li className="condition-item">
          It triggers the release of mood boosting enzymes and hormones, which
          help improve mental energy and well-being.
        </li>
      </ul>
    </div>
  );
};

export default ReflexologyBullets;
