import React, { useEffect } from "react";
import { InlineWidget } from "react-calendly";

const Book = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="outer-form">
      <InlineWidget url="https://calendly.com/astrengthinyou" />
    </div>
  );
};

export default Book;
