import React from "react";
import "../../../styles/conditions.css";

const LymphaticList = () => {
  return (
    <>
      <div className="condition-grid" style={{marginBottom: "0px"}}>
        <ul className="condition-list-dual">
          <li className="condition-item">Glandular Fever</li>
          <li className="condition-item">M.E.</li>
          <li className="condition-item">Fibromyalgia</li>
          <li className="condition-item">Sinus problems</li>
          <li className="condition-item">Oedema</li>
          <li className="condition-item">Headaches and migraines</li>
          <li className="condition-item">Acne, rosacea and scarring</li>
        </ul>
        <ul className="condition-list-dual">
          <li className="condition-item">Headaches and migraines</li>
          <li className="condition-item">Sinus congestion</li>
          <li className="condition-item">Aches and pains</li>
          <li className="condition-item">Minor injuries</li>
          <li className="condition-item">Stress anxiety and tension</li>
          <li className="condition-item">Digestive conditions</li>
          <li className="condition-item">Issues after lymph node removal</li>
        </ul>
      </div>
      <div className="condition-grid">
        <ul className="condition-list-solo">
          <li className="condition-item">
            It is useful pre, and post surgeries, to remove tissue congestion
            and enhance the healing process and to minimise scar formation
          </li>
        </ul>
      </div>
    </>
  );
};

export default LymphaticList;
