import Accordion from "react-bootstrap/Accordion";

import React from "react";
import "../../../styles/accordion.css";

const MfrAccordion = () => {
  return (
    <div className="accordion-container">
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header style={{ color: "#036c7b" }}>
            What happens during a treatment?
          </Accordion.Header>
          <Accordion.Body>
            <div className="flex-wrapper-inner">
              <p className="treatment-text-2">
                You will be relaxed on a therapy bed. It is imperative to remove
                clothing depending on the areas of treatment. You will be
                covered with towels and blankets. Your dignity and respect will
                be always considered.
              </p>
              <p className="treatment-text-2">
                The therapist does not use oils.
              </p>
              <p className="treatment-text-2">
                The therapist moves over the body, stretching, applying pressure
                and skin rolling to release the memory of the tissue.
              </p>
              <p className="treatment-text-2">
                Your session time will be discussed prior to the treatment and,
                depending on the reasons for treatment, can last from 30 minutes
                to 50 minutes.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Contraindications to myofascial release
          </Accordion.Header>
          <Accordion.Body>
            <div className="flex-wrapper-inner">
              <p className="treatment-text-2">
                As will all treatments there are contraindications
              </p>
              <p className="treatment-text-2">
                You must not book a treatment if you currently have:
              </p>
              <div className="treatment-grid">
                <ul className="condition-list">
                  <li className="condition-item">
                    Unstable medical conditions
                  </li>
                  <li className="condition-item">Open wounds</li>
                  <li className="condition-item">Open wounds</li>
                  <li className="condition-item">
                    Acute inflammation or infection (with or without fever)
                  </li>
                  <li className="condition-item">Current infection</li>
                  <li className="condition-item">Recent fractures</li>
                  <li className="condition-item">Current infection</li>
                  <li className="condition-item">Recent fractures</li>
                  <li className="condition-item">
                    Severe osteoporosis or advanced degenerative changes
                  </li>
                  <li className="condition-item">Rheumatoid flares</li>
                  <li className="condition-item">Cellulitis</li>
                  <li className="condition-item">
                    Acute circulatory conditions
                  </li>
                  <li className="condition-item">
                    Cellulitis
                  </li>
                  <li className="condition-item">Anticoagulant treatments</li>
                  <li className="condition-item">Obstructive oedema</li>
                  <li className="condition-item">Osteomyelitiss</li>
                  <li className="condition-item">Advanced diabetes</li>
                  <li className="condition-item">Skin hypersensitivity</li>
                  <li className="condition-item">Cancer</li>
                  <li className="condition-item">Chronic hypermobile joints</li>
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default MfrAccordion;
