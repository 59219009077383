import React, { useEffect } from "react";
import strengthLogo from "../../assets/strengthLogo.png";
import massage from "../../assets/oncology-massage.jpeg";
import mfrWord from "../../assets/mfr_word.png";
import BookingButton from "./SubComponents/BookingButton";
import "../../styles/treatments.css";
import OncologyAccordion from "../Treatments/SubComponents/OncologyAccordion";

const Oncology = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="outer-treatments">
      <img className="main-logo-treatments" src={strengthLogo} />
      <section className="treatment-section">
        <h3 className="treatment-heading">Oncology Massage</h3>
        <p className="treatment-text">
          Is modified a massage therapy performed by a qualified therapist to
          safely work with the effects of cancer and its treatments.
        </p>
        <p className="treatment-text">
          It is suitable for those having treatment, post treatment, those in
          remission and even caregivers.
        </p>
        <p className="treatment-text" style={{ fontWeight: "bold" }}>
          There is evidence based research that a gentle holistic massage cannot
          spread cancer.
        </p>
      </section>
      <div className="phone-image-container-landscape">
        <img className="wide-treatment-image" src={mfrWord} />
      </div>
      <section className="treatment-section">
        <h3 className="treatment-heading">
          There is research to show that the modified massage can:
        </h3>
        <div className="condition-grid">
          <ul className="condition-list-solo">
            <li className="condition-item">Relax your mind and body</li>
            <li className="condition-item">
              Improve your mood reducing anxiety and depression
            </li>
            <li className="condition-item">
              Provide comfort and relief from emotional stress and minor
              digestive concerns
            </li>
            <li className="condition-item">
              Relieve tension and pain in muscles or tissue
            </li>
            <li className="condition-item">Improve circulation</li>
            <li className="condition-item">Improve sleep</li>
            <li className="condition-item">Reduce headaches</li>
            <li className="condition-item">Enhance wellbeing</li>
            <li className="condition-item">
              Decrease inflammation and swelling
            </li>
            <li className="condition-item">
              Reduce chemotherapy induced peripheral neuropathy
            </li>
            <li className="condition-item">
              Reduce chemotherapy induced nausea
            </li>
          </ul>
        </div>
        <div className="phone-image-container-landscape">
          <img className="wide-treatment-image" src={massage} />
        </div>
        <div className="treatment-separator"></div>
        <OncologyAccordion />
      </section>
      <div className="treatment-separator"></div>
      <h3 className="treatment-heading">How do I book my session with you?</h3>
      <p className="treatment-text">
        Use our Contact form to book a session with me.
      </p>
      <BookingButton />
      <div className="treatment-separator"></div>
    </div>
  );
};

export default Oncology;
