import React, { useEffect } from "react";
import strengthLogo from "../../assets/strengthLogo.png";
import reflexology from "../../assets/reflexology.webp";
import BookingButton from "./SubComponents/BookingButton";
import "../../styles/treatments.css";
import styled from "styled-components";
import ReflexologyBullets from "./SubComponents/ReflexologyBullets";
import ReflexologyBulletsLarge from "./SubComponents/ReflexologyBulletsLarge";

const MobileWrapper = styled.div`
  display: none;
  @media screen and (max-width: 619px) {
    display: block;
  }
`;

const DesktopWrapper = styled.div`
  display: none;
  @media screen and (min-width: 620px) {
    display: block;
  }
`;

const Reflexology = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="outer-treatments">
      <img className="main-logo-treatments" src={strengthLogo} />
      <section className="treatment-section">
        <h3 className="treatment-heading">Reflexology</h3>
        <p className="treatment-text">
          Reflexology is a technique that applies gentle pressure to your feet,
          hands or ears. At A Strength In You, we perform a treatment on your
          feet, to bring about a state of relaxation and to help your body's own
          healing process.
        </p>
        <div className="treatment-separator"></div>
        <h3 className="treatment-heading">What is Reflexology?</h3>
        <p className="treatment-text">
          Reflexology works in a similar way to acupressure and acupuncture.
          There are certain points on the feet that correspond to the organs and
          glands in the body. By pressing, holding and massaging these points it
          can stimulate energy pathways within your body.
        </p>
        <p className="treatment-text">
          When energy pathways are blocked, reflexology aims to unblock them,
          allowing the energy to flow freely again, aiming to restore balance to
          the body. It can stimulate energy, blood, nutrition, or nerves,
          resulting in therapeutic effects, including relieving mental stress,
          detoxifying the body, promoting blood circulation, and improving
          internal health.
        </p>
        <div className="ear-phone-image-container">
          <img className="wide-treatment-image" src={reflexology} />
        </div>
        <div className="treatment-separator"></div>
        <h3 className="treatment-heading">Where did the practice originate?</h3>
        <p className="treatment-text">
          Reflexology is an ancient practice originating from China, Egypt and
          North American indigenous tribes, used for healing. It was made
          accessible to the Western world by Dr. William Fitzgerald and his
          pioneering work in the early 1900s. He introduced “zone therapy” which
          emphasized the connection between reflex areas on the hands and feet
          and various organs and areas of the body within the same zone.
        </p>
        <p className="treatment-text">
          Eunice Ingham played a pivotal role in refining and popularising
          reflexology during the 1930’s, by building on both the work and
          studies of Dr Fitzgerald. Ingham believed that congestion, or tension,
          in specific areas of the foot reflected similar conditions in
          corresponding parts of the body. Her work has significantly influenced
          the modern practice and understanding of reflexology as we know it
          today. During the 19th century the medical community and complementary
          and alternative medicine (CAM) practitioners began to explore it
          scientifically.
        </p>
      </section>
      <section>
        <div className="treatment-separator"></div>
        <h3 className="treatment-heading">
          Why should you choose this treatment?
        </h3>
        <p className="treatment-text">
          On the physical, emotional, and spiritual levels, reflexology often
          improves an individuals physical health and well-being. Concisely,
          people choose reflexology because:
        </p>
        <ReflexologyBullets />

        <div className="treatment-separator"></div>

        <p className="treatment-text">
          The World Health Organization (WHO) stated in 1990 that the
          “combination of traditional drug-free podiatric medicine can improve
          individuals’ understanding of their health”.
        </p>

        <p className="treatment-text">
          It is becoming more common to note that foot reflexology is gradually
          being used as a non-invasive complementary and alternative therapy to
          promote health restoration and the revitalization of various body
          parts at all ages, as it has been proposed to enhance blood flow,
          relax the body, and improve injury healing.
        </p>

        <p className="treatment-text">
          In addition, foot reflexology is also an effective treatment for
          strokes, insomnia, asthma, diabetes, premenstrual syndrome, dementia,
          cancers, multiple sclerosis, and idiopathic detrusor overactivity. It
          has also been studied for use in hospice units to alleviate discomfort
          and pain in patients at the end of life.
        </p>
        {/* <div className="treatment-separator"></div> */}
      </section>
      <div className="treatment-separator"></div>
      <h3 className="treatment-heading">How do I book my session with you?</h3>
      <p className="treatment-text">
        Use our Contact form to book a session with me.
      </p>
      <BookingButton />
      <div className="treatment-separator"></div>
    </div>
  );
};

export default Reflexology;
