import React from "react";
import "../../../styles/conditions.css";

const MenopauseBullets = () => {
  return (
    <div className="condition-grid">
      <ul className="condition-list-dual">
        <li className="condition-item">Night sweats</li>
        <li className="condition-item">Heart palpitations</li>
        <li className="condition-item">Poor concentration</li>
        <li className="condition-item">Regular urinary tract infections</li>
        <li className="condition-item">Irregular/erratic periods</li>
        <li className="condition-item">Anxiety</li>
        <li className="condition-item">Reduced sex drive</li>
        <li className="condition-item">Brain fog</li>
        <li className="condition-item">Fatigue</li>
        <li className="condition-item">Insomnia</li>
        <li className="condition-item">Dizziness / Vertigo</li>
        <li className="condition-item">Weight gain / loss</li>
        <li className="condition-item">Sore mouth and bleeding gums</li>
        <li className="condition-item">
          Sensitivity to heat or cold, or flushes
        </li>
        <li className="condition-item">
          Joint and muscle pain / tenderness and inflammation
        </li>
      </ul>
      <ul className="condition-list-dual">
        <li className="condition-item">Digestive issues</li>
        <li className="condition-item">Sore breasts</li>
        <li className="condition-item">Frustration</li>
        <li className="condition-item">Visual and hearing changes</li>
        <li className="condition-item">Painful intercourse</li>
        <li className="condition-item">Incontinence</li>
        <li className="condition-item">Mood swings</li>
        <li className="condition-item">Dry eyes</li>
        <li className="condition-item">Irritability</li>
        <li className="condition-item">Acne</li>
        <li className="condition-item">Dry / Itchy Skin</li>
        <li className="condition-item">Vaginal itching pain / dryness</li>
        <li className="condition-item">Changes in body odour</li>
        <li className="condition-item">
          Facial hair appearing, head hair thinning
        </li>
        <li className="condition-item">
          Pins and needles / sensations of “electric shocks" in
          extremities
        </li>
      </ul>
    </div>
  );
};

export default MenopauseBullets;
