import React, { useEffect } from "react";
import "../styles/about.css";
import strengthLogo from "../assets/strengthLogo.png";
import jacky from "../assets/jacky.jpg";
import FHT from "../assets/FHT-Logo.png";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="outer-about">
      <img className="main-logo-about" src={strengthLogo} />
      <section className="about-section">
        <h3 className="welcome">Privacy Policy</h3>
        <p
          className="subheading"
          style={{ textAlign: "center", marginBottom: "20px" }}
        >
          September 1 st 2023
        </p>
        <p className="privacy-text">The main trading address:</p>
        <p className="address-text">Camden House</p>
        <p className="address-text">Dudley Road</p>
        <p className="address-text">Darlington</p>
        <p className="address-text-last">DL1 4GG</p>
        <p className="subheading">Personal Data is:</p>
        <p className="privacy-text">
          Information relating to an identified or identifiable person, for
          example name, age, address, date of birth, gender, phone numbers,
          email addresses and any other contact details are classed as personal
          data. Personal data is that which may be classed as “sensitive”.
        </p>
        <p className="privacy-text">
          This could be information regarding that of{" "}
          <u>
            <b>health matters</b>
          </u>
          , racial information, religious, political, or philosophical beliefs,
          union membership, biometric, genetic, or sexual orientation data.
          Anything relating to criminal offences or convictions is also deemed
          as sensitive.{" "}
          <u>
            <b>HEALTH MATTERS</b> are the only above information needed by A
            Strength In You.
          </u>
        </p>
        <p className="privacy-text">
          We will not ask for information for surveys, feedback or competitions.
          We do use patient reported outcome measures (PROMs) to ensure the
          treatments are helpful, these are anonymous once completed.
        </p>
        <p className="subheading">Cookies are used on our websites.</p>
        <p className="privacy-text">
          If you object to us having any personal information which would not be
          detrimental to your treatments then you can omit it.
        </p>
        <p className="subheading">For your treatment we will need to ask:</p>
        <ol>
          <li style={{ textAlign: "left", marginBottom: "10px" }}>Name</li>
          <li style={{ textAlign: "left", marginBottom: "10px" }}>Address</li>
          <li style={{ textAlign: "left", marginBottom: "10px" }}>
            Date of birth
          </li>
          <li style={{ textAlign: "left", marginBottom: "10px" }}>
            Contact number or email for purposes as above
          </li>
          <li style={{ textAlign: "left", marginBottom: "10px" }}>
            Payment information
          </li>
          <li style={{ textAlign: "left", marginBottom: "10px" }}>
            Information regarding medical conditions and medical history
          </li>
        </ol>
        <p className="subheading" style={{ marginBottom: "15px" }}>
          If you object to the collection and storage of your personal data, we
          may not be able to provide our services.
        </p>
        <p className="privacy-text">
          If you object to giving medical information regarding medical history
          or sharing to another health care professional in the event of an
          emergency, this may negate or services to you also.
        </p>
        <p className="privacy-text">
          We will store the information you provide for the benefit of yourself
          and treatments. We do not share your personal information with any
          other companies.
        </p>
        <p className="privacy-text">
          If you wish to receive information regarding other services we
          provide, you will be advised websites, leaflets and other literature.
        </p>
        <p className="privacy-text">
          We store information securely for 10 years in accordance with our
          insurance policy. Under data protection law, you have rights
          including:
        </p>
        <ol>
          <li style={{ textAlign: "left", marginBottom: "10px" }}>
            Your right of access - You have the right to ask us for copies of
            your personal information.
          </li>
          <li style={{ textAlign: "left", marginBottom: "10px" }}>
            Your right to rectification - You have the right to ask us to
            rectify personal information you think is inaccurate. You also have
            the right to ask us to complete information you think is incomplete.
          </li>
          <li style={{ textAlign: "left", marginBottom: "10px" }}>
            Your right to erasure - You have the right to ask us to erase your
            personal information in certain circumstances.
          </li>
          <li style={{ textAlign: "left", marginBottom: "10px" }}>
            Your right to restriction of processing - You have the right to ask
            us to restrict the processing of your personal information in
            certain circumstances.
          </li>
          <li style={{ textAlign: "left", marginBottom: "10px" }}>
            Your right to object to processing - You have the right to object to
            the processing of your personal information in certain
            circumstances.
          </li>
          <li style={{ textAlign: "left", marginBottom: "10px" }}>
            Your right to data portability - You have the right to ask that we
            transfer the personal information you gave us to another
            organisation, or to you, in certain circumstances.
          </li>
        </ol>
        <p className="privacy-text">
          You are not required to pay any charge for exercising your rights. If
          you make a request, we have one month to respond to you. Please
          contact us at:{" "}
          <b>
            <a
              href="mailto:astrengthinyou@gmail.com"
              target="_blank"
              style={{ color: "black" }}
            >
              astrengthinyou@gmail.com
            </a>{" "}
          </b>
          or <b>07858 761633</b> if you wish to make a request.
        </p>
        <p className="privacy-text">
          We will contact you to advise of building moves or additional
          treatments or discounts. You can opt out at any time, (Your right to
          restriction of processing). We will us email addresses and home
          addresses to be in touch with you for updates and marketing.
        </p>
        <p className="privacy-text">
          Under the UK General Data Protection Regulation (UK GDPR), the lawful
          bases we rely on for processing this information are:
        </p>
        <ol>
          <li style={{ textAlign: "left", marginBottom: "10px" }}>
            Your consent. You are able to remove your consent at any time. You
            can do this by contacting{" "}
            <b>
              <a
                href="mailto:astrengthinyou@gmail.com"
                target="_blank"
                style={{ color: "black" }}
              >
                astrengthinyou@gmail.com
              </a>{" "}
            </b>
          </li>
          <li style={{ textAlign: "left", marginBottom: "10px" }}>
            We have a contractual obligation
          </li>
          <li style={{ textAlign: "left", marginBottom: "10px" }}>
            We have a legal obligation (Insurance purposes)
          </li>
          <li style={{ textAlign: "left", marginBottom: "10px" }}>
            We have a vital interest
          </li>
          <li style={{ textAlign: "left", marginBottom: "10px" }}>
            We have a legitimate interest
          </li>
        </ol>
      </section>
    </div>
  );
};

export default Privacy;
