import Accordion from "react-bootstrap/Accordion";

import React from "react";
import "../../../styles/accordion.css";

const LymphaticAccordion = () => {
  return (
    <div className="accordion-container">
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header style={{ color: "#036c7b" }}>
            What happens during a treatment?
          </Accordion.Header>
          <Accordion.Body>
            <div className="flex-wrapper-inner">
              <p className="treatment-text-2">
                You will be relaxed on a therapy bed; it is imperative to remove
                clothing depending on the areas of treatment. You will be
                covered with towels and blankets. Your dignity and respect will
                be always considered.
              </p>
              <p className="treatment-text-2">
                The therapist does not use oils.
              </p>
              <p className="treatment-text-2">
                The therapist moves down the body, draining lymph vessels using
                very gentle touch with either a pumping action, stationary
                circular, rotary, or scoop stroke.
              </p>
              <p className="treatment-text-2">
                Your session time will be discussed prior to the treatment and,
                depending on the reasons for treatment, can last from 20 minutes
                to one hour.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Contraindications</Accordion.Header>
          <Accordion.Body>
            <div className="flex-wrapper-inner">
              <p className="treatment-text-2">
                As will all treatments there are contraindications
              </p>
              <p className="treatment-text-2">
                You must not book a treatment if you currently have:
              </p>
              <div className="treatment-grid">
                <ul className="condition-list">
                  <li className="condition-item">
                    An infection, inflammatory disease, or fever
                  </li>
                  <li className="condition-item">
                    Active cancer or undiagnosed lumps
                  </li>
                  <li className="condition-item">Malignant melanomas</li>
                  <li className="condition-item">
                    Serious circulatory conditions (such as thrombosis)
                  </li>
                  <li className="condition-item">Major heart conditions</li>
                </ul>
              </div>
              <p className="treatment-text-2">
                There maybe times or conditions when consent from your
                Consultant or GP is required, prior to a treatment.
              </p>
              <p className="treatment-text-2">
                If you are going aboard for treatment, please consider obtaining
                a written discharge letter with consent from the team you were
                under.
              </p>
              <p className="treatment-text-2">
                Some conditions may be treated with modifications to the
                treatment. For example, anyone with a thyroid concern could have
                a treatment – but the neck would not be touched. People with low
                blood pressure may have initial treatments lasting a short
                period of time, building up to longer sessions.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>After your treatment</Accordion.Header>
          <Accordion.Body>
            <div className="flex-wrapper-inner">
              <p className="treatment-text-2">
                You may, due to the self healing process:
              </p>
              <div className="treatment-grid">
                <ul className="condition-list">
                  <li className="condition-item">
                    Need to pass urine more frequently
                  </li>
                  <li className="condition-item">
                    Have a runny nose or a cough
                  </li>
                  <li className="condition-item">
                    Notice a slight rash as skin is rebalancing
                  </li>
                  <li className="condition-item">
                    Perspire more as the skin is excreting waste
                  </li>
                  <li className="condition-item">
                    Have a temporary flare of a condition that was suppressed,
                    before the healing process takes place
                  </li>
                  <li className="condition-item">
                    Experience a deep sleep, or difficulty sleeping, and vivid
                    dreams.
                  </li>
                </ul>
              </div>
              <p className="treatment-text-2">
                These reactions are temporary and should subside within 24 to 48
                hours. They are very positive signals your body is responding to
                the treatment and is re-balancing.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            How to get maximum benefit from your treatment
          </Accordion.Header>
          <Accordion.Body>
            <div className="flex-wrapper-inner">
              <p className="treatment-text-2">
                To ensure maximum benefit from the treatment, it is recommended
                that you:
              </p>
              <div className="treatment-grid">
                <ul className="condition-list">
                  <li className="condition-item">
                    Loosen tight clothing to avoid restrictions to lymph flow
                  </li>
                  <li className="condition-item">
                    Increase the amount of water you drink for the next 48 hours
                    at least
                  </li>
                  <li className="condition-item">
                    Reduce your caffeine intake
                  </li>
                  <li className="condition-item">
                    Avoid hot bathing, showers and saunas for 12 hours after
                    treatment
                  </li>
                  <li className="condition-item">
                    Avoid alcohol for 24 hours after treatment
                  </li>
                  <li className="condition-item">Try to cut down on smoking</li>
                  <li className="condition-item">Take time to relax</li>
                  <li className="condition-item">
                    Encourage gentle exercise such as yoga or pilates, or
                    non-brisk walking
                  </li>
                  <li className="condition-item">
                    Eat helpfully for the body. Raw foods can be of more help
                    for the first 24 hours. Salads and fruit are beneficial.
                  </li>
                  <li className="condition-item">
                    Try diaphragmatic breathing a few times a day, with legs
                    elevated if possible.
                  </li>
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default LymphaticAccordion;
