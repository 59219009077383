import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import { BsCartPlus } from "react-icons/bs";
import {
  MdOutlineWavingHand,
  MdOutlineMedication,
  MdOutlinePrivacyTip,
} from "react-icons/md";

export const SidebarData = [
  {
    title: "Home",
    path: "/home",
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "All Treatments",
    path: "/services/all_treatments",
    icon: <MdOutlineMedication />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "CranioSacral Therapy",
        path: "/services/craniosacral_therapy",
        icon: <MdOutlineMedication />,
      },
      {
        title: "Somato Emotional Release",
        path: "/services/somato_emotional_release",
        icon: <MdOutlineMedication />,
      },
      {
        title: "Colonic Hydrotherapy",
        path: "/services/colonic_hydrotherapy",
        icon: <MdOutlineMedication />,
        cName: "sub-nav",
      },
      {
        title: "Reflexology",
        path: "/services/reflexology",
        icon: <MdOutlineMedication />,
        cName: "sub-nav",
      },
      {
        title: "Managing Menopause",
        path: "/services/menopause",
        icon: <MdOutlineMedication />,
        cName: "sub-nav",
      },
      {
        title: "Health Check",
        path: "/services/healthcheck",
        icon: <MdOutlineMedication />,
        cName: "sub-nav",
      },
      {
        title: "Oncology Massage",
        path: "/services/oncology_massage",
        icon: <MdOutlineMedication />,
      },
      {
        title: "Nutritional Assessment and Guidance",
        path: "/services/nutritional_therapy",
        icon: <MdOutlineMedication />,
      },
      {
        title: "Lymphatic Drainage massage",
        path: "/services/lymphatic_massage",
        icon: <MdOutlineMedication />,
      },
      {
        title: "Myofascial Release",
        path: "/services/myofascial_release",
        icon: <MdOutlineMedication />,
      },
      {
        title: "Pain Management",
        path: "/services/pain_management",
        icon: <MdOutlineMedication />,
      },
      {
        title: "Health Coaching",
        path: "/services/health_coaching",
        icon: <MdOutlineMedication />,
      },
      {
        title: "Reiki Seichem",
        path: "/services/reiki_seichem",
        icon: <MdOutlineMedication />,
      },
      {
        title: "Meditation",
        path: "/services/meditation",
        icon: <MdOutlineMedication />,
      },
      {
        title: "IV Nutrient Therapy",
        path: "/services/iv_nutrient_therapy",
        icon: <MdOutlineMedication />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "About",
    path: "/about",
    icon: <MdOutlineWavingHand />,
  },
  {
    title: "Testimonials",
    path: "/testimonials",
    icon: <IoIcons.IoMdPeople />,
  },
  {
    title: "Book",
    path: "/book",
    icon: <BsCartPlus />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Contact Me",
    path: "/contact",
    icon: <RiIcons.RiContactsLine />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Terms and Conditions",
    path: "/terms",
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Privacy Policy",
    path: "/privacy",
    icon: <MdOutlinePrivacyTip />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
];
